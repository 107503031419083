import { put, all, call } from 'redux-saga/effects';
import { globalSagaFailure } from "./global.action";
import AuthorizationSaga from "redux/actions/authorization/authorization.saga.js";
import PeopleResults from "redux/actions/peopleresults/peopleresults.saga.js";
import expertTypeAPISaga from "redux/actions/expertTypes/expertTypes.saga.js";
import openRequestAPISaga from './actions/openRequests/openRequests.saga';
import requestTypeAPISaga from './actions/requestType/requestType.saga';
import saveInterviewRequestAPISaga from './actions/saveInterviewRequest/saveInterviewRequest.saga';
import externalNetworkAPISaga from './actions/externalNetwork/externalNetwork.saga';
import requestDetailsAPISaga from "redux/actions/requestDetails/requestDetails.saga";
import templatesAPISaga from 'redux/actions/templateFilters/templateFilters.saga';
import chargeCodeSaga from 'redux/actions/chargeCode/chargeCode.saga';
import chargeCodeDetails from 'redux/actions/chargeCodeDetails/chargeCodeDetails.saga';
import uploadFileSaga from './actions/uploadFile/uploadFile.saga';
import consentAPISaga from './actions/privacyConsent/privacyConsent.saga';
import saveConsentRequestAPISaga from './actions/savePrivacyConsent/savePrivacyConsent.saga';
import commTemplatesAPISaga from './actions/communicationTemplates/communicationTemplates.saga';
import deleteFileRequestAPISaga from './actions/deleteAttachment/deleteAttachment.saga';
import renameFileRequestAPISaga from './actions/renameAttachment/renameAttachment.saga';
import approveTemplatesAPISaga from 'redux/actions/approveTemplates/approveTemplates.saga';
import editTemplatesAPISaga from './actions/editTemplate/editTemplate.saga';
import employeeDesignationDataAPISaga from './actions/employeeDesignation/employeeDesignation.saga';
import expReceivedAPISaga from './actions/expertReceived/expertReceived.saga';
import saveProfileActionRequestAPISaga from './actions/expertProfileActions/expertProfileActions.saga';
import expRecFiltersAPISaga from './actions/expRecFilters/expRecFilters.saga';
import shareViaEmailAPISaga from './actions/shareViaEmail/shareViaEmail.saga';
import saveCallSlotsAPISaga from './actions/saveCallSlots/saveCallSlots.saga';
import callsAPISaga from './actions/calls/calls.saga';
import callsFiltersAPISaga from './actions/callsFilters/callsFilters.saga';

export default function* globalSaga() {
    try {
        yield all([
            call(AuthorizationSaga),
            call(PeopleResults),
            call(expertTypeAPISaga),
            call(openRequestAPISaga),
            call(requestTypeAPISaga),
            call(saveInterviewRequestAPISaga),
            call(externalNetworkAPISaga),
            call(requestDetailsAPISaga),
            call(templatesAPISaga),
            call(chargeCodeSaga),
            call(chargeCodeDetails),
            call(uploadFileSaga),
            call(consentAPISaga),
            call(saveConsentRequestAPISaga),
            call(commTemplatesAPISaga),
            call(deleteFileRequestAPISaga),
            call(renameFileRequestAPISaga),
            call(approveTemplatesAPISaga),
            call(editTemplatesAPISaga),
            call(employeeDesignationDataAPISaga),
            call(expReceivedAPISaga),
            call(saveProfileActionRequestAPISaga),
            call(expRecFiltersAPISaga),
            call(shareViaEmailAPISaga),
            call(saveCallSlotsAPISaga),
            call(callsAPISaga),
            call(callsFiltersAPISaga)
        ]);
    } catch (err) {
        yield put(globalSagaFailure(err))
    }
}