import { connectRouter, routerMiddleware } from "connected-react-router";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import history from "redux/history";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import {
  name as authorizationReducerName,
  reducer as authorizationReducer,
} from "./reducers/authorization/authorization_reducer";

import {
  name as peopleReducerName,
  reducer as peopleReducer,
} from "./reducers/peopleresults/peopleresults_reducer";

import {
  name as userSessionName,
  reducer as userSessionReducer,
} from "./reducers/usersession/usersession_reducer";

import {
  name as globalErrorName,
  reducer as globalErrorReducer
} from './reducers/error/error_reducer.js';

import {
  name as testAPIName,
  reducer as testAPIReducer
} from "./reducers/testapi/testapi_reducer.js";

import {
  name as expertTypesName,
  reducer as expertTypeReducer
} from './reducers/expertTypes/expertTypes_reducer.js';

import {
  name as openRequestsName,
  reducer as openRequestsReducer
} from './reducers/openRequests/openRequests_reducer.js';

import {
  name as requestTypeName,
  reducer as requestTypeReducer
} from './reducers/requestType/requestType_reducer.js';

import {
  name as saveRequestName,
  reducer as saveRequestReducer
} from './reducers/saveRequest/saveRequest_reducer.js';

import {
  name as externalNetworkName,
  reducer as externalNetworkReducer
} from './reducers/externalNetwork/externalNetwork_reducer.js';

import {
  name as requestDetailsName,
  reducer as requestDetailsReducer
} from './reducers/requestDetails/requestDetails_reducer.js';

import {
  name as templateFiltersName,
  reducer as templateFiltersReducer
} from './reducers/templateFilters/templateFilters_reducer.js';

import {
  name as chargeCodeName,
  reducer as chargeCodeReducer
} from './reducers/chargeCode/chargeCode_reducer.js';

import {
  name as chargeCodeDetailsName,
  reducer as chargeCodeDetailsReducer
} from './reducers/chargeCodeDetails/chargeCodeDetails_reducer';

import {
  name as uploadFileName,
  reducer as uploadFileReducer
} from './reducers/uploadFile/uploadFile_reducer.js';

import {
  name as consentName,
  reducer as privacyConsentReducer
} from './reducers/privacyConsent/privacyConsent_reducer';

import {
  name as saveConsentName,
  reducer as saveConsentReducer
} from './reducers/saveConsent/saveConsent_reducer';

import {
  name as commTemplatesName,
  reducer as commTemplatesReducer
} from './reducers/communicationTemplates/communicationTemplates_reducer';

import {
  name as deleteAttachmentName,
  reducer as deleteAttachmentReducer
} from './reducers/deleteAttachment/deleteAttachment_reducer';

import {
  name as renameAttachmentName,
  reducer as renameAttachmentReducer
} from './reducers/renameAttachment/renameAttachment_reducer';

import {
  name as approveTemplatesName,
  reducer as approveTemplatesReducer
} from './reducers/approveTemplates/approveTemplates_reducer';

import {
  name as editTemplateName,
  reducer as editTemplateReducer
} from './reducers/editTemplate/editTemplate_reducer';

import {
  name as empDesignationName,
  reducer as empDesignationReducer
} from './reducers/employeeDesignation/employeeDesignation_reducer';

import {
  name as expReceivedName,
  reducer as expReceivedReducer
} from './reducers/expertReceived/expertReceived_reducer';

import {
  name as expProfileActionsName,
  reducer as expProfileActionsReducer
} from './reducers/expertProfileActions/expertProfileActions_reducer';

import {
  name as expRecFiltersName,
  reducer as expRecFiltersReducer
} from './reducers/expRecFilters/expRecFilters_reducer';

import {
  name as shareViaEmailName,
  reducer as shareViaEmailReducer
} from './reducers/shareViaEmail/shareViaEmail_reducer';

import {
  name as saveCallSlotsName,
  reducer as saveCallSlotsReducer
} from './reducers/saveCallSlots/saveCallSlots_reducer';

import {
  name as requestCallsName,
  reducer as requestCallsReducer
} from './reducers/calls/calls_reducer';

import {
  name as callsFiltersName,
  reducer as callsFiltersReducer
} from './reducers/callsFilters/callsFilters_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  thunkMiddleware,
  sagaMiddleware,
  routerMiddleware(history),
  createLogger({ collapsed: true }),
];

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    [authorizationReducerName]: authorizationReducer,
    [peopleReducerName]: peopleReducer,
    [userSessionName]: userSessionReducer,
    [globalErrorName]: globalErrorReducer,
    [testAPIName]: testAPIReducer,
    [expertTypesName]: expertTypeReducer,
    [openRequestsName]: openRequestsReducer,
    [requestTypeName]: requestTypeReducer,
    [saveRequestName]: saveRequestReducer,
    [externalNetworkName]: externalNetworkReducer,
    [requestDetailsName]: requestDetailsReducer,
    [templateFiltersName]: templateFiltersReducer,
    [chargeCodeName]: chargeCodeReducer,
    [chargeCodeDetailsName]: chargeCodeDetailsReducer,
    [uploadFileName]: uploadFileReducer,
    [consentName]: privacyConsentReducer,
    [saveConsentName]: saveConsentReducer,
    [commTemplatesName]: commTemplatesReducer,
    [deleteAttachmentName]: deleteAttachmentReducer,
    [renameAttachmentName]: renameAttachmentReducer,
    [approveTemplatesName]: approveTemplatesReducer,
    [editTemplateName]: editTemplateReducer,
    [empDesignationName]: empDesignationReducer,
    [expReceivedName]: expReceivedReducer,
    [expProfileActionsName]: expProfileActionsReducer,
    [expRecFiltersName]: expRecFiltersReducer,
    [shareViaEmailName]: shareViaEmailReducer,
    [saveCallSlotsName]: saveCallSlotsReducer,
    [requestCallsName]: requestCallsReducer,
    [callsFiltersName]: callsFiltersReducer
  }),
  composeEnhancers(applyMiddleware(...middleware))
);
const runSaga = sagaMiddleware.run;
export { store, runSaga };
