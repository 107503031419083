import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HtmlEditor from 'devextreme-react/html-editor';
import Image from 'components/shared/Image';
import { formatDate } from 'utils/formatDate/formatDate';
import { getEditTemplatesAPIData } from "redux/actions/editTemplate/editTemplate.actions";
import { getPeopleAPIData } from 'redux/actions/peopleresults/peopleresults.actions';
import { selectors as requestDetailsSelector } from 'redux/reducers/requestDetails/requestDetails_reducer';
import { selectors as peopleResultsSelector } from 'redux/reducers/peopleresults/peopleresults_reducer';
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import EditTemplate from 'assets/images/edit-template.svg';
import SaveTemplate from 'assets/images/save-template.svg';
import CancelTemplate from 'assets/images/cancel-template.svg';
import ShareTemplateIcon from 'assets/images/shareTemplate.svg';
import ApproveTemplateIcon from 'assets/images/approveTemplate.svg';
import OutlookIcon from 'assets/images/outlook-icon.svg';
import LocationIcon from 'assets/images/location-icon.svg';
import 'devextreme/dist/css/dx.light.css';
import './Template.scss';
import '../TeamMembers/TeamMembers.scss';
import authService from 'redux/auth';
import { getRandomColor } from 'utils/helpers/helpers';
import { CustomTooltip } from 'components/shared/CustomTooltip/CustomTooltip';


export const Template = ({ handleshowApproveAll, handleCancel, status, templatedata, templateDetails, expandedData, id, handleApproveTemplate, empDes, approvalLoading }) => {
    const approverInputRef = useRef();
    const dispatch = useDispatch();
    const reqDetailsData = useSelector(requestDetailsSelector.getRequestDetailsResults);
    const peopleResults = useSelector(peopleResultsSelector.getPeopleResults);
    const [getEmptyTemplate, setEmptyTemplate] = useState(false);
    const [accessToken, setAccessToken] = useState([]);
    const [caseTeamData, setCaseTeamData] = useState(null);
    const [htmlContent, setHtmlContent] = useState(templatedata);
    const [isEditing, setIsEditing] = useState(false);
    const [data, setData] = useState([]);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [selectedApprover, setSelectedApprover] = useState('');
    const [showApproverError, setShowApproverError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editTempTimestamp, setEditTempTimestamp] = useState();

    useEffect(() => {
        console.log('approvalLoading', approvalLoading);
        setLoading(approvalLoading);
    }, [approvalLoading]);

    useEffect(() => {
        setAccessToken(authService.getAccessToken());
    }, []);

    // Fetch case team members on component mount or when reqDetailsData changes
    useEffect(() => {
        if (reqDetailsData?.caseTeamMembers?.length > 0) {
            setData(reqDetailsData.caseTeamMembers);
        }
    }, [reqDetailsData]);

    const getEmptyTemplateFunc = () => {
        return getEmptyTemplate;
    }

    useEffect(() => {
        if (htmlContent?.length > 0)
            setEmptyTemplate(false);
        else
            setEmptyTemplate(true);
    }, [htmlContent]);

    useEffect(() => {
        let timeout;
        function updateSelection(e) {
            if (e.detail && e.detail.length > 0) {
                setSelectedApprover(e.detail[0].email);
                setShowApproverError(false);
            } else {
                setSelectedApprover('');
            }
        }
        if (approverInputRef && approverInputRef.current) {
            const shadowRoot = approverInputRef.current.shadowRoot;
            timeout = setTimeout(() => {
                const element = shadowRoot.querySelector('.selection-list');
                if (element) {
                    element.style.width = '100%';
                    element.style.height = '48px';
                    element.style.background = 'var(--Fills-light, #FFF)';
                }
            }, 800);

            approverInputRef.current.addEventListener("selectedPeopleChanged", updateSelection, false);
        }

        return () => clearTimeout(timeout);
    }, [openTooltip]);

    // Fetch people data based on templateDetails
    useEffect(() => {
        if (templateDetails) {
            let hrid = templateDetails.status === 'Awaiting Approval'
                ? templateDetails.editedBy
                : templateDetails.approvedBy;
                if(templateDetails?.templateId == expandedData?.templateId){
                    hrid = expandedData?.editedBy;
                }
            dispatch(getPeopleAPIData(hrid || ''));
        }
    }, [templateDetails?.templateId, dispatch, expandedData]);

    // Map people results to caseTeamData based on hrId
    useEffect(() => {
        if (peopleResults.length > 0 && templateDetails) {
            let hrid = templateDetails.status === 'Awaiting Approval'
            ? templateDetails.editedBy
            : templateDetails.approvedBy;

            if(templateDetails?.templateId == expandedData?.templateId){
                setEditTempTimestamp(expandedData?.lastUpdatedTimestamp);
                if(expandedData?.editedBy){
                    hrid = expandedData.editedBy;
                }
            } else {
                setEditTempTimestamp(templateDetails?.lastUpdatedTimestamp);
            }
            const matchedData = peopleResults.find(person => person?.id === hrid);
            if (matchedData && hrid) {
                const respData = matchedData;
                respData.picture = respData?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : respData.picture;
                const nameArray = respData?.preferredName ? respData?.preferredName.split(', ') : [];
                let userInitials = '';
                if (nameArray.length > 0) {
                    userInitials = nameArray.length > 1 ? nameArray[1].charAt(0) + nameArray[0].charAt(0) : nameArray[0].charAt(0);
                } else {
                    userInitials = respData?.firstName.charAt(0);
                }
                respData.nameInitials = userInitials;
                respData.bgColor = getRandomColor();
                setCaseTeamData(respData);
            }
        }
    }, [peopleResults, templateDetails, expandedData]);

    const handleValueChange = (e) => {
        setHtmlContent(e.value);
    };

    const handleEditClick = () => {
        setIsEditing(true);
        handleshowApproveAll(false);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        let tempTemplatedata = {
            ...templateDetails,
            template: htmlContent
        }
        let finalTemplatedata = {
            "templateId": tempTemplatedata?.templateId,
            "requestId": tempTemplatedata?.requestId,
            "networkProvider": tempTemplatedata?.networkProvider,
            "expertTypes": tempTemplatedata?.expertTypes,
            "template": tempTemplatedata?.template,
            "status": tempTemplatedata?.status
        };
        if (htmlContent?.length > 0) {
            dispatch(getEditTemplatesAPIData(finalTemplatedata));
        }
        // handleCancel();
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setHtmlContent(templatedata);
        handleCancel();
    };

    const handleProfileRedirection = (hrid) => {
        window.open(`https://profiles-stg.navigator.bcg.com/overview?hrid=${hrid}`);
    };

    const displayUserCurrentTime = (timeZone) => {
        const date = new Date();
        const options = {
            timeZone: timeZone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return date.toLocaleTimeString("en-US", options);
    };

    return (
        <div className="template__wrap">
            {!isEditing ? (
                <div className='editSave__templateWrap'>
                    <div className='edit__template'>
                        <div className='edit--templateWrap'>
                            {status === 'Awaiting Approval' && (
                                <div className='template--data'>
                                    <div className='template--colon'>Template :</div>
                                    <div className='template--subText' dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                                    <div className='approvedBy__On'>
                                        <div className='approved--by'>
                                            <div className='approved--byText'>Edited By: </div>
                                            {/* <TeamMembers data={data} templatePage={true} /> */}
                                            <div className="teamMembers__wrapper templatePage__wrapper">
                                                <div className="teamMembers__bodyWrap templatePage__bodywrapper">
                                                    {caseTeamData && (
                                                        <div className="teamMembers__body" onClick={() => handleProfileRedirection(caseTeamData.id)}>
                                                            <div className="teamMembers__bodyTop">
                                                                <div className="user--profile">
                                                                    {caseTeamData.picture ?
                                                                        <Image src={caseTeamData.picture} alt="profile NP" className='user--img' /> :
                                                                        <span
                                                                            style={{ background: caseTeamData.bgColor }}
                                                                            className={`user--img custom--profile--pic`}>
                                                                            {caseTeamData.nameInitials}
                                                                        </span>}
                                                                </div>
                                                                <div className="user__body">
                                                                    <div className="body--name">{caseTeamData.firstName} {caseTeamData.lastName}</div>
                                                                    <div className="body--designation">{caseTeamData.jobTitle}</div>
                                                                </div>
                                                            </div>
                                                            <div className="teamMembers__bodyBottom">
                                                                <div className="outlook__wrap">
                                                                    <Image src={OutlookIcon} alt="OutlookIcon" className='outlook--img' />
                                                                </div>
                                                                <div className="user__location">
                                                                    <Image src={LocationIcon} alt="LocationIcon" className='location--img' />
                                                                    <span className="location--text">{caseTeamData.homeOffice} - {displayUserCurrentTime(caseTeamData.hostOfficeLocation?.timezone)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='approved--on'>
                                            <div className='approved--onText'>Edited On: </div>
                                            <div className='approvedon--date'>{formatDate(editTempTimestamp)}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {status === 'Awaiting Template' && (
                                <div className='template--data'>
                                    <span className='template--text'>Template not yet received from the network. Once submitted, it will appear here for approval. Please check back later or contact the network if you require immediate submission.</span>
                                </div>
                            )}
                            {status === 'Approved' && (
                                <div className='template--data'>
                                    <div className='template--colon'>Template :</div>
                                    <div className='template--subText' dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                                    <div className='approvedBy__On'>
                                        <div className='approved--by'>
                                            <div className='approved--byText'>Approved By: </div>
                                            <div className="teamMembers__wrapper templatePage__wrapper">
                                                <div className="teamMembers__bodyWrap templatePage__bodywrapper">
                                                    {caseTeamData ? (
                                                        <div className="teamMembers__body" onClick={() => handleProfileRedirection(caseTeamData.id)}>
                                                            <div className="teamMembers__bodyTop">
                                                                <div className="user--profile">
                                                                    {caseTeamData.picture ?
                                                                        <Image src={caseTeamData.picture} alt="profile NP" className='user--img' /> :
                                                                        <span
                                                                            style={{ background: caseTeamData.bgColor }}
                                                                            className={`user--img custom--profile--pic`}>
                                                                            {caseTeamData.nameInitials}
                                                                        </span>}
                                                                </div>
                                                                <div className="user__body">
                                                                    <div className="body--name">{caseTeamData.firstName} {caseTeamData.lastName}</div>
                                                                    <div className="body--designation">{caseTeamData.jobTitle}</div>
                                                                </div>
                                                            </div>
                                                            <div className="teamMembers__bodyBottom">
                                                                <div className="outlook__wrap">
                                                                    <Image src={OutlookIcon} alt="OutlookIcon" className='outlook--img' />
                                                                </div>
                                                                <div className="user__location">
                                                                    <Image src={LocationIcon} alt="LocationIcon" className='location--img' />
                                                                    <span className="location--text">{caseTeamData.homeOffice} - {displayUserCurrentTime(caseTeamData.hostOfficeLocation?.timezone)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                        :
                                                        <div className="teamMembers__body">
                                                            <div className="teamMembers__bodyTop">
                                                                <ImageParaSkeleton showPara={true} paraCount={3} size="fullWidth" height={4} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='approved--on'>
                                            <div className='approved--onText'>Approved On: </div>
                                            <div className='approvedon--date'>{formatDate(templateDetails?.approvedTimestamp)}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {status === 'Awaiting Approval' && (
                                <div className='template__editwrap'>
                                    <button onClick={handleEditClick} className='edit--template'><Image src={EditTemplate} className='edit--icon' alt="EditTemplate" />Edit Template</button>
                                    <div className='shareApprove__template'>
                                        <div className='share__template'>
                                            <button
                                                // className={(empDes?.includes('Outside Consultant') || empDes?.includes('Principal') || empDes?.includes('Partners') || empDes?.includes('Associate Director') || empDes?.includes('Managing Director')) ? 'share--template' : 'share--template approved--opacity'}
                                                className={loading? 'share--template disabled--btn': 'share--template'}
                                                disabled={loading}
                                                onClick={(event) => {
                                                    // if (empDes?.includes('Outside Consultant') || empDes?.includes('Principal') || empDes?.includes('Partners') || empDes?.includes('Associate Director') || empDes?.includes('Managing Director')) {
                                                    setOpenTooltip(!openTooltip);
                                                    setSelectedApprover('');
                                                    // }
                                                }}>
                                                <Image src={ShareTemplateIcon} className='edit--icon' alt="ShareTemplateIcon" />
                                                <span className='approver-button'>Share via Email for Approval</span>
                                            </button>
                                            {
                                                openTooltip &&
                                                <CustomTooltip open={openTooltip} selectedApprover={selectedApprover} handleClosePopup={(value) => { setOpenTooltip(value) }} handleCancel={handleCancel} templateDetails={templateDetails} isTemplate={true} />
                                            }
                                        </div>
                                        <div className='approve__template'>
                                            <button className={(empDes?.includes('Outside Consultant') || empDes?.includes('Principal') || empDes?.includes('Partners') || empDes?.includes('Associate Director') || empDes?.includes('Managing Director')) ? 'approve--template' : 'approve--template approved--opacity'} onClick={() => {
                                                if ((empDes?.includes('Outside Consultant') || empDes?.includes('Principal') || empDes?.includes('Partners') || empDes?.includes('Associate Director') || empDes?.includes('Managing Director'))) {
                                                    handleApproveTemplate('approve', templateDetails)
                                                }
                                            }
                                            }><Image src={ApproveTemplateIcon} className='edit--icon' alt="ApproveTemplateIcon" />Approve</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='edittemplate'>
                        <div className='template--dataedit'>
                            <HtmlEditor
                                value={htmlContent}
                                onValueChanged={handleValueChange}
                            />
                        </div>
                        <div className='savecancel__btn'>
                            <button onClick={handleSaveClick} disabled={getEmptyTemplateFunc()} className={getEmptyTemplate ? 'save--template save--opacity' : 'save--template'}><Image src={SaveTemplate} className='edit--icon' alt="SaveTemplate" />Save Changes</button>
                            <button onClick={handleCancelClick} className='cancel--template'><Image src={CancelTemplate} className='edit--icon' alt="CancelTemplate" />Cancel</button>
                        </div>
                    </div>
                    <div className='shareApprove__template shareApprove__editTtemplate'>
                        <div className='share__template'>
                            <button className='share--template'><Image src={ShareTemplateIcon} className='edit--icon' alt="ShareTemplateIcon" />Share via Email for Approval</button>
                        </div>
                        <div className='approve__template'>
                            <button className={(empDes?.includes('Outside Consultant') || empDes?.includes('Principal') || empDes?.includes('Partners') || empDes?.includes('Associate Director') || empDes?.includes('Managing Director')) ? 'approve--template' : 'approve--template approved--opacity'} onClick={() => {
                                if ((empDes?.includes('Outside Consultant') || empDes?.includes('Principal') || empDes?.includes('Partners') || empDes?.includes('Associate Director') || empDes?.includes('Managing Director'))) {
                                    handleApproveTemplate('approve', templateDetails)
                                }
                            }
                            }><Image src={ApproveTemplateIcon} className={'edit--icon'} alt="ApproveTemplateIcon" />Approve</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
